import React, { useEffect } from "react";
import { useIsSale } from "SkyflagFrontendOfferwall/modules/useIsSale";
import Styles from "SkyflagFrontendOfferwall/stylesheets/index/PointResult.module.scss";
import CommonStyles from "SkyflagFrontendOfferwall/stylesheets/CommonStyles.module.scss";
import FontStyles from "SkyflagFrontendOfferwall/stylesheets/FontStyles.module.scss";
import clsx from "clsx";
import { formatComma } from "utiles/formatComma";

interface SuperSale {
  startAt: Date;
  endAt: Date;
  point: number;
}

export interface Props {
  superSale: SuperSale;
  pointIconUrl: string;
  pointUnit: string;
  basePointMin: number;
  actualPointMin: number;
  basePoint: number;
  actualPoint: number;
  isSur: boolean;
}

export const SurveyPointResult = ({
  pointIconUrl,
  pointUnit,
  basePoint,
  basePointMin,
  superSale,
  actualPoint,
  actualPointMin,
  isSur,
}: Props) => {
  // SUR, セールをふまえたポイントの結果を表示する関数

  const pointIcon: JSX.Element = (
    <>{pointIconUrl && <img src={pointIconUrl} alt="Pt" className={CommonStyles.pointIcon} />}</>
  );
  const [now, isInCurrencySale, confirmInCurrencySale, , , , , , , setDefault] = useIsSale();

  useEffect(() => {
    confirmInCurrencySale(superSale.startAt, superSale.endAt);
    return () => {
      setDefault();
    };
  }, [now]);

  const surPointCalc = (): JSX.Element => {
    const resultText: JSX.Element =
      isInCurrencySale && actualPoint !== basePoint ? (
        <>
          {pointIcon}
          <span className={clsx(Styles.before, FontStyles.tiny)}>
            {formatComma(basePointMin)}〜{formatComma(basePoint)}
          </span>
          <span className={Styles.arrow}>▶︎</span>
          {pointIcon}
          <span className={clsx(Styles.after, FontStyles.bold)}>
            {formatComma(actualPointMin)}〜{formatComma(actualPoint)}
          </span>
        </>
      ) : (
        <>
          {pointIcon}
          <span className={FontStyles.bold}>
            {formatComma(actualPointMin)}〜{formatComma(actualPoint)}
            {pointUnit}
          </span>
        </>
      );

    return resultText;
  };

  const pointCalc = (): JSX.Element => {
    const resultText: JSX.Element =
      isInCurrencySale && basePoint !== actualPoint ? (
        <>
          {pointIcon}
          <span className={clsx(Styles.before, FontStyles.tiny)}>{formatComma(basePoint)}</span>
          <span className={Styles.arrow}>▶︎</span>
          {pointIcon}
          <span className={clsx(Styles.after, FontStyles.bold)}>{formatComma(actualPoint)}</span>
        </>
      ) : (
        <>
          {pointIcon}
          <span className={FontStyles.bold}>
            {formatComma(actualPoint)} {pointUnit}
          </span>
        </>
      );
    return resultText;
  };

  return (
    <div className={clsx(Styles.result, CommonStyles.center)} data-testid="result">
      {isSur ? surPointCalc() : pointCalc()}
    </div>
  );
};
