import { useMemo } from "react";
import { Media } from "models";
import { plainToClass } from "class-transformer";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { selectMediaState } from "store/selector";
import GoogleAnalyticsTag from "./GoogleAnalyticsTag";

export const Head: React.FC = () => {
  const media = useSelector(selectMediaState);
  const title = media?.id === 162 || media?.id === 163 ? media?.wallTitle : media?.name;
  const { colors, googleAnalyticsTrackingId } = useMemo(() => plainToClass(Media, media), [media]);
  const queries = new URLSearchParams(window.location.search);
  const _owp = queries.get("_owp") ?? "";

  const isIndex =
    _owp === process?.env?.REACT_APP_OWP_162 || _owp === process?.env?.REACT_APP_OWP_163;
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="theme-color" content={colors?.titleBgColor} />
        {!isIndex && <meta name="robots" content="noindex" />}
      </Helmet>
      {googleAnalyticsTrackingId && (
        <GoogleAnalyticsTag googleAnalyticsTrackingId={googleAnalyticsTrackingId} />
      )}
    </>
  );
};
