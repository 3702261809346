import { useState, useEffect } from "react";

export const useIsSale = (): [
  Date,
  boolean,
  (start: Date, end: Date) => void,
  boolean,
  (start: Date, end: Date) => void,
  boolean,
  (start: Date, end: Date) => void,
  boolean,
  (start: Date, end: Date) => void,
  () => void
] => {
  const [now, setNow] = useState(new Date());
  const [isInTimeSale, setInTimeSale] = useState(false);
  const [isInCurrencySale, setInCurrencySale] = useState(false);
  const [isInBonus, setInBonus] = useState(false);
  const [isInSuperSaleSpecifiedOffer, setInSuperSaleSpecifiedOffer] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return function () {
      clearInterval(intervalId);
    };
  }, []);

  // セール開始からの時間と終了までの時間を計算して出力する関数
  const calcTime = (start: Date, end: Date): { afterBegin: number; beforeEnd: number } => {
    const currentTime: number = now.getTime();
    const saleStartTime: number = new Date(start).getTime();
    const saleEndTime: number = new Date(end).getTime();
    const afterBegin: number = currentTime - saleStartTime; // セール開始からの秒数
    const beforeEnd: number = saleEndTime - currentTime; // セール終了までの秒数
    return { afterBegin, beforeEnd };
  };

  const confirmInCurrencySale = (saleStart: Date, saleEnd: Date): void => {
    if (!saleStart || !saleEnd) setInCurrencySale(false); // 開始時刻または終了時刻が未設定であればfalse
    calcTime(saleStart, saleEnd).afterBegin > 0 && calcTime(saleStart, saleEnd).beforeEnd > 0
      ? setInCurrencySale(true)
      : setInCurrencySale(false); // 設定された時間内であればtrue
  };

  const confirmInTimeSale = (saleStart: Date, saleEnd: Date): void => {
    if (!saleStart || !saleEnd) {
      setInTimeSale(false);
    } else {
      calcTime(saleStart!, saleEnd!).afterBegin > 0 && calcTime(saleStart!, saleEnd!).beforeEnd > 0
        ? setInTimeSale(true)
        : setInTimeSale(false); // 設定された時間内であればtrue
    }
  };

  const confirmInBonus = (startDate: Date | null, endDate: Date | null): void => {
    if (!startDate || !endDate) {
      setInBonus(false);
    } else {
      calcTime(startDate!, endDate!).afterBegin > 0 && calcTime(startDate!, endDate!).beforeEnd > 0
        ? setInBonus(true)
        : setInBonus(false); // 設定された時間内であればtrue
    }
  };

  const confirmInSuperSaleSpecifiedOffer = (startDate: Date | null, endDate: Date | null): void => {
    if (!startDate || !endDate) {
      setInSuperSaleSpecifiedOffer(false);
    } else {
      calcTime(startDate!, endDate!).afterBegin > 0 && calcTime(startDate!, endDate!).beforeEnd > 0
        ? setInSuperSaleSpecifiedOffer(true)
        : setInSuperSaleSpecifiedOffer(false);
    }
  };

  const setDefault = () => {
    confirmInTimeSale(null, null);
    confirmInCurrencySale(null, null);
    confirmInSuperSaleSpecifiedOffer(null, null);
  };

  return [
    now,
    isInCurrencySale,
    confirmInCurrencySale,
    isInTimeSale,
    confirmInTimeSale,
    isInBonus,
    confirmInBonus,
    isInSuperSaleSpecifiedOffer,
    confirmInSuperSaleSpecifiedOffer,
    setDefault,
  ];
};
