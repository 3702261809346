import { SuperSaleSpecifiedOffer } from "models/superSaleSpecifiedOffer";
import { SuperSale } from "SkyflagFrontendOfferwall/components/index/PointResult";

export const getSuperSaleIntegration = (
  superSale: SuperSale,
  superSaleSpecifiedOffers: SuperSaleSpecifiedOffer[],
  offerId: number
): SuperSale => {
  if (superSale && superSale.startAt && superSale.endAt) return superSale;
  if (superSaleSpecifiedOffers) {
    const superSaleSpecifiedOffer = superSaleSpecifiedOffers.find(
      (x) => x.offerId === offerId && x.pointMultiplier > 1
    );
    if (superSaleSpecifiedOffer)
      return {
        startAt: superSaleSpecifiedOffer.startAt,
        endAt: superSaleSpecifiedOffer.endAt,
        point: superSaleSpecifiedOffer.pointMultiplier,
        isSpecifiedOffer: true,
      };
  }
  return { point: null, startAt: null, endAt: null };
};
