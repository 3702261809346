import React from "react";
import { Helmet } from "react-helmet-async";

interface IProps {
  googleAnalyticsTrackingId: string;
}

const GoogleAnalyticsTag: React.FC<IProps> = ({ googleAnalyticsTrackingId }) => (
  <Helmet>
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsTrackingId}`}
    />
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${googleAnalyticsTrackingId}');
        dataLayer.push({
          'event': 'virtualPageview'
        });
      `}
    </script>
  </Helmet>
);

export default GoogleAnalyticsTag;
