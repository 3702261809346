import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
  RefObject,
} from "react";
import defaultPath from "utiles/path";
import { selectDisplayIndexState } from "store/selector";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ClockBanner } from "components/shared/ClockBanner";
import PikkomaOffersList from "./OffersList";
import { OffersList } from "SkyflagFrontendOfferwall/components/index/OffersList";
import { SurveyList } from "SkyflagFrontendOfferwall/components/index/SurveyList";
import { OffersListAB } from "SkyflagFrontendOfferwall/components/index/OffersListAB";
import { OthersSurveyList } from "components/index/OthersSurveyList";
import stylesResponsive from "stylesheets/index/MainContents.module.scss";
import stylesPortrait from "stylesheets/index/MainContentsPortrait.module.scss";
import CommonStyles from "stylesheets/CommonStyles.module.scss";
import { tabsRename } from "modules/tabs";
import { afterChangeTabs } from "utiles/tabs";
import { topChallenging, Media, Survey } from "models";
import { Footer } from "components/shared/Footer";
import FontStyles from "stylesheets/FontStyles.module.scss";
import clsx from "clsx";
import { Tabs } from "../../slices/offerwall";
import { SwipeSlider } from "components/common/SwipeSlider";
import { Clock } from "SkyflagFrontendOfferwall/components/shared/Clock";
import { offerType } from "types/offerwallType";
import { OfferCard } from "../common/OfferCard";
import { SurveyBanner } from "../common/SurveyBanner";
import { superSaleType } from "types/superSaleBannerType";
import { surveyDisplayType } from "types/surveyDisplayType";
import { PickupOffers, CurrentOffers } from "interfaces/offers";
import { DisplayTabType } from "utiles/offer";
import DisplayIndexModules from "slices/displayIndex";
import { HorizontalBox } from "../common/HorizontalBox";
import { OfferBlock } from "components/common/OfferBlock";
import { MENU_TYPE } from "types/MenuType";
import { filterMenus } from "modules/filterMenus";
import { TopSurvey } from "models/topSurvey";
import { getSuperSaleIntegration } from "modules/superSales";
import { ExternalOfferBlock } from "components/common/ExternalOfferBlock";
import { createGetNextSurveyForOffer } from "utiles/createGetNextSurveyForOffer";

interface Props {
  offers: CurrentOffers[];
  media: Media;
  tabs: Tabs[];
  challengings: topChallenging[];
  surveys: TopSurvey[];
  abTestSurveys: Survey[];
  isFixedMenu: boolean;
  fetchPaginate: () => void;
  PickupOffers: PickupOffers[];
  onScrollTopToTabs?: ({ active, next }: { active: number; next: number }) => void;
  BannerElement: JSX.Element;
  isTabsScroll: boolean;
  isLandscape: boolean;
}

const MainContents: React.FC<Props> = React.memo(
  ({
    offers,
    tabs,
    challengings,
    surveys,
    abTestSurveys,
    isFixedMenu,
    fetchPaginate,
    media,
    PickupOffers,
    onScrollTopToTabs,
    BannerElement,
    isTabsScroll,
    isLandscape,
  }: Props) => {
    const { displayIndex } = useSelector(selectDisplayIndexState);
    const dispatch = useDispatch();
    const history = useHistory();
    const ref = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;
    const queries = new URLSearchParams(window.location.search);
    const tab = Number(queries.get("tab") ?? tabs[0]?.tab);
    const [intersecting, setIntersecting] = useState(false);
    const [isHorizontalScroll, setIsHorizontalScroll] = useState<boolean>(false);
    const tabNumbers = tabs.map((data) => data.tab);
    const defaultTabStyle: React.CSSProperties = {
      color: media.colors.tabTxtColor,
      fontSize: media.offerwallType !== offerType.MANGA && "12px",
    };

    const isPiccoma =
      media.offerwallType === offerType.PICCOMA_APP ||
      media.offerwallType === offerType.PICCOMA_WEB;

    const isPickUp = media.offerwallType === offerType.PICKUP;

    const Styles = media.offerwallType === offerType.TRIP_MILE ? stylesPortrait : stylesResponsive;

    const selectedTabStyle: React.CSSProperties = {
      color: media.colors.selectTabColor,
      borderColor: `${media.colors.selectTabColor}`,
      fontSize: media.offerwallType !== offerType.MANGA && "12px",
    };

    const handleChangeHistory = useCallback((id: number, index: number) => {
      onScrollTopToTabs({
        active: index,
        next: id,
      });
      history.replace({
        pathname: `${defaultPath.INDEX}`,
        search: new URLSearchParams({
          ...Object.fromEntries(queries.entries()),
          tab: String(id),
        }).toString(),
      });
    }, []);

    useLayoutEffect(() => {
      dispatch(DisplayIndexModules.actions.setDisplayIndex(tabNumbers.indexOf(Number(tab))));
    }, []);

    const handleClickTab = (id: number, index: number) => {
      dispatch(DisplayIndexModules.actions.setDisplayIndex(index));
      handleChangeHistory(id, index);
    };

    const handleChangeSlide = useCallback((NextTabId: number, index: number) => {
      handleChangeHistory(NextTabId, index);
      dispatch(DisplayIndexModules.actions.setDisplayIndex(index));
    }, []);

    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });

    const setPagenateRef = useCallback(
      (node: HTMLDivElement) => {
        ref.current = node;
        if (ref.current) observer.observe(ref.current);
      },
      [ref, fetchPaginate]
    );

    useEffect(() => {
      if (intersecting && fetchPaginate) {
        fetchPaginate();
      }
    }, [intersecting, tab]);

    // 遷移時にURLにタブパラメータを追加
    useEffect(() => {
      if (!queries.get("tab")) {
        history.replace({
          pathname: `${defaultPath.INDEX}`,
          search: new URLSearchParams({
            ...Object.fromEntries(queries.entries()),
            tab: String(tab),
          }).toString(),
        });
      }
    }, []);

    // メニューに挑戦中が出ない時は、オススメ中段の挑戦中も非表示にする
    const isChallengeDisplay = useMemo(
      () => filterMenus(media.menus).some((menu) => menu.menuType === MENU_TYPE.CHALLENGING),
      [media.menus]
    );

    const containerRef: RefObject<HTMLDivElement | null> = useRef(null);
    const tabRefs: RefObject<HTMLElement[] | null> = useRef([]);
    const scrollToTab = (tabRef: HTMLElement) => {
      if (containerRef.current !== null) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const tabRect = tabRef.getBoundingClientRect();

        // タブが画面外にはみ出ている場合、スクロールする
        if (tabRect.right > containerRect.right) {
          containerRef.current.scrollBy({
            left: tabRect.left - containerRect.left,
            behavior: "smooth", // スクロールを滑らかにする
          });
        } else if (tabRect.left < containerRect.left) {
          containerRef.current.scrollBy({
            left: tabRect.right - containerRect.right,
            behavior: "smooth", // スクロールを滑らかにする
          });
        }
        if (tabRect.left < containerRect.left && tabRect.right > containerRect.right) {
          containerRef.current.scrollBy({
            left: tabRect.left - containerRect.left,
            behavior: "smooth", // スクロールを滑らかにする
          });
        }
      }
    };

    const getNextSurveyForOffer = createGetNextSurveyForOffer();

    return (
      <>
        <div className="data-menu">
          <div className={Styles["menu-body"]}>
            <div
              ref={containerRef}
              className={
                media.offerwallType === offerType.MANGA
                  ? clsx(
                      Styles["standerd-tabs"],
                      isFixedMenu && Styles["is-active"],
                      isTabsScroll && tabs.length >= 6 && Styles["slider-contents-tabs"]
                    )
                  : media.offerwallType === offerType.TRIP_MILE
                  ? clsx(
                      Styles["torima-tabs"],
                      Styles["is-active"],
                      isTabsScroll && tabs.length >= 6 && Styles["slider-contents-tabs"]
                    )
                  : clsx(
                      Styles["pikkoma-tabs"],
                      isFixedMenu && Styles["is-active"],
                      isTabsScroll && tabs.length >= 6 && Styles["slider-contents-tabs"]
                    )
              }
              data-testid="menuTab"
              style={{
                backgroundColor: media.colors.tabBgColor,
              }}
            >
              {(media.offerwallType === offerType.MANGA
                ? tabs
                : tabsRename(tabs, afterChangeTabs)
              ).map((data, i) => (
                <p
                  onClick={() => {
                    handleClickTab(Number(data.tab), i);
                    scrollToTab(tabRefs.current[i]);
                  }}
                  className={clsx(
                    isTabsScroll && tabs.length >= 6 ? Styles.tab : Styles.tab_over,
                    isTabsScroll && tabs.length >= 6 ? Styles.scrolltab_title : Styles.tab_title,
                    FontStyles.large,
                    FontStyles.bold,
                    CommonStyles.center
                  )}
                  key={i}
                  style={Number(tab) === Number(data.tab) ? selectedTabStyle : defaultTabStyle}
                  ref={(ref) => {
                    tabRefs.current[i] = ref;
                  }}
                >
                  {data.name}
                </p>
              ))}
            </div>
            <div className={Styles["menu-body-right"]}>
              {!media.isBannerTabHidden && !isPiccoma && isLandscape && (
                <div className={Styles["banner-horizontal"]}>{BannerElement}</div>
              )}
              <div className={Styles.background}>
                <SwipeSlider
                  index={displayIndex}
                  tabNumbers={tabNumbers}
                  onSlideChange={(id: number, index: number) => {
                    handleChangeSlide(id, index);
                  }}
                  horizontalScroll={isHorizontalScroll}
                  childrenList={[
                    ...Object.entries(offers).map(
                      ([key, value], i) =>
                        value && (
                          <div
                            className={
                              (media.banners.length && !isPiccoma) ||
                              media.offerwallType === offerType.TRIP_MILE
                                ? clsx(
                                    Styles["slider-contents-pickup"],
                                    displayIndex === i && "is-slide-active",
                                    displayIndex === i && Styles["is-active"]
                                  )
                                : clsx(
                                    Styles["slider-contents"],
                                    displayIndex === i && "is-slide-active"
                                  )
                            }
                            {...(displayIndex === i && { "data-testid": "activeTab" })}
                            key={key}
                          >
                            {media.superSaleBannerType === superSaleType.BANNER && (
                              <div className={Styles.banner}>
                                <ClockBanner
                                  start={media.primarySuperSaleStartAt}
                                  end={media.primarySuperSaleEndAt}
                                  pointMultiplier={media.primarySuperSalePointMultiplier}
                                  offerwallType={media.offerwallType}
                                />
                              </div>
                            )}
                            {(() => {
                              // TODO Switch文をreturnの外へ持っていく
                              switch (value.tabDisplayType) {
                                case DisplayTabType.BigImage:
                                  return (
                                    <>
                                      {media.surveyDisplayType === surveyDisplayType.TAB ? (
                                        <>
                                          {value &&
                                            value.offers.slice(0, 2).map((offer, i) => (
                                              <OfferCard
                                                color={{
                                                  appAreaBgColor: media.colors.appAreaBgColor,
                                                  appAreaTxtColor: media.colors.appAreaTxtColor,
                                                  expointTxtColor: media.colors.expointTxtColor,
                                                  cvConditionColor: media.colors.cvConditionColor,
                                                  dlBtnBgColor: media.colors.dlBtnBgColor,
                                                  dlBtnTxtColor: media.colors.dlBtnTxtColor,
                                                }}
                                                pointIconUrl={media.pointIconUrl}
                                                videoIconUrl={media.videoIconUrl}
                                                isVideo={offer.isVideo}
                                                superSale={getSuperSaleIntegration(
                                                  {
                                                    ...media?.superSale,
                                                    point: media?.superSale?.pointMultiplier,
                                                  },
                                                  media?.superSaleSpecifiedOffers,
                                                  offer.id
                                                )}
                                                isButton={!!media}
                                                timeSale={{
                                                  startAt:
                                                    offer.conversionPoints.slice(-1)[0]?.timeSale
                                                      ?.startAt,
                                                  endAt:
                                                    offer.conversionPoints.slice(-1)[0]?.timeSale
                                                      ?.endAt,
                                                }}
                                                bonus={{
                                                  point:
                                                    offer.conversionPoints.slice(-1)[0]?.bonus
                                                      ?.point,
                                                  startAt:
                                                    offer.conversionPoints.slice(-1)[0]?.bonus
                                                      ?.startAt,
                                                  endAt:
                                                    offer.conversionPoints.slice(-1)[0]?.bonus
                                                      ?.endAt,
                                                }}
                                                dlBtnName={
                                                  offer.advertisementId
                                                    ? media.dlBtnNameApp
                                                    : media.dlBtnNameWeb
                                                }
                                                basePoint={offer.basePoint}
                                                actualPoint={offer.actualPoint}
                                                isSur={offer.isSur}
                                                displayName={offer?.displayName}
                                                cvCondition={offer?.cvCondition}
                                                listImage={offer.listImageUrl}
                                                isPercentage={offer.isPercentage}
                                                search={{
                                                  offerId: offer.id,
                                                  position: i + 1 + 0,
                                                  linkFrom: "ow",
                                                }}
                                                isPickUp={isPickUp}
                                                pointUnit={media.pointUnit}
                                                offerwallType={media.offerwallType}
                                                tutorialOffer={{
                                                  isTutorialOffer: offer.isTutorialOffer,
                                                  tutorialOfferActionUrl:
                                                    offer.tutorialOfferActionUrl,
                                                }}
                                                key={i}
                                              />
                                            ))}
                                        </>
                                      ) : (
                                        <>
                                          {value &&
                                            value.offers.slice(0, 1).map((offer, i) => (
                                              <OfferCard
                                                color={{
                                                  appAreaBgColor: media.colors.appAreaBgColor,
                                                  appAreaTxtColor: media.colors.appAreaTxtColor,
                                                  expointTxtColor: media.colors.expointTxtColor,
                                                  cvConditionColor: media.colors.cvConditionColor,
                                                  dlBtnBgColor: media.colors.dlBtnBgColor,
                                                  dlBtnTxtColor: media.colors.dlBtnTxtColor,
                                                }}
                                                pointIconUrl={media.pointIconUrl}
                                                videoIconUrl={media.videoIconUrl}
                                                isVideo={offer.isVideo}
                                                superSale={getSuperSaleIntegration(
                                                  {
                                                    ...media?.superSale,
                                                    point: media?.superSale?.pointMultiplier,
                                                  },
                                                  media?.superSaleSpecifiedOffers,
                                                  offer.id
                                                )}
                                                isButton={!!media}
                                                timeSale={{
                                                  startAt:
                                                    offer.conversionPoints.slice(-1)[0]?.timeSale
                                                      ?.startAt,
                                                  endAt:
                                                    offer.conversionPoints.slice(-1)[0]?.timeSale
                                                      ?.endAt,
                                                }}
                                                bonus={{
                                                  point:
                                                    offer.conversionPoints.slice(-1)[0]?.bonus
                                                      ?.point,
                                                  startAt:
                                                    offer.conversionPoints.slice(-1)[0]?.bonus
                                                      ?.startAt,
                                                  endAt:
                                                    offer.conversionPoints.slice(-1)[0]?.bonus
                                                      ?.endAt,
                                                }}
                                                dlBtnName={
                                                  offer.advertisementId
                                                    ? media.dlBtnNameApp
                                                    : media.dlBtnNameWeb
                                                }
                                                basePoint={offer.basePoint}
                                                actualPoint={offer.actualPoint}
                                                isSur={offer.isSur}
                                                displayName={offer?.displayName}
                                                cvCondition={offer?.cvCondition}
                                                listImage={offer.listImageUrl}
                                                isPercentage={offer.isPercentage}
                                                search={{
                                                  offerId: offer.id,
                                                  position: i + 1 + 0,
                                                  linkFrom: "ow",
                                                }}
                                                key={i}
                                                isPickUp={isPickUp}
                                                pointUnit={media.pointUnit}
                                                offerwallType={media.offerwallType}
                                                tutorialOffer={{
                                                  isTutorialOffer: offer.isTutorialOffer,
                                                  tutorialOfferActionUrl:
                                                    offer.tutorialOfferActionUrl,
                                                }}
                                              />
                                            ))}
                                          <SurveyBanner
                                            color={{
                                              appAreaBgColor: media.colors.appAreaBgColor,
                                            }}
                                            BannerImage={media?.bannerImageUrl}
                                            search={{
                                              position: 2,
                                              linkFrom: "lb",
                                            }}
                                            offerwallType={media.offerwallType}
                                          />
                                        </>
                                      )}
                                      {isPiccoma && BannerElement}
                                      <div
                                        className={Styles.listWrapper}
                                        style={{ backgroundColor: media.colors.appAreaBgColor }}
                                      >
                                        {isChallengeDisplay && (
                                          <div
                                            className={clsx(
                                              Styles["list-container"],
                                              "swipe-disable"
                                            )}
                                            data-position={3.3}
                                            // 横スクロール中のタブ切り替えを制御
                                            onTouchMove={() => {
                                              if (challengings.length > 4)
                                                setIsHorizontalScroll(true);
                                            }}
                                            onTouchEnd={() => {
                                              setIsHorizontalScroll(false);
                                            }}
                                          >
                                            <HorizontalBox
                                              isMore={challengings && challengings.length > 0}
                                              type="challenge"
                                              title={
                                                media.offerwallType === offerType.LINE_MANGA
                                                  ? media?.menus
                                                      .filter((menu) => menu.menuType === 2)
                                                      .map((menu) => menu.title)[0]
                                                  : "チャレンジ中（達成待ち）"
                                              }
                                              to={{
                                                pathname: `${defaultPath.INDEX}/challenge`,
                                                search: `${location.search}&position=3.3`,
                                                state: {
                                                  display: "チャレンジ中",
                                                  referrer: {
                                                    pathname: location.pathname,
                                                    search: location.search,
                                                  },
                                                },
                                              }}
                                              style={{
                                                color:
                                                  media.offerwallType === offerType.LINE_MANGA &&
                                                  media.colors.appAreaTxtColor,
                                              }}
                                            >
                                              {challengings?.length > 0 ? (
                                                challengings
                                                  ?.filter((_, i) => i < 10)
                                                  .map((data, index) => (
                                                    <OfferBlock
                                                      key={index}
                                                      to={{
                                                        pathname: `${defaultPath.INDEX}/challenge/detail`,
                                                        search: `${location.search}&offer=${data.id}&position=3.3`,
                                                        state: {
                                                          display: "チャレンジ中",
                                                          referrer: {
                                                            pathname: location.pathname,
                                                            search: location.search,
                                                          },
                                                        },
                                                      }}
                                                      iconUrl={data.iconUrl}
                                                      superSale={getSuperSaleIntegration(
                                                        {
                                                          ...media?.superSale,
                                                          point: media?.superSale?.pointMultiplier,
                                                        },
                                                        media?.superSaleSpecifiedOffers,
                                                        data.id
                                                      )}
                                                      actualPoint={data.actualPoint}
                                                      pointIconUrl={media.pointIconUrl}
                                                      isSur={data.isSur}
                                                      text={
                                                        data.isSur ? media.stepUpRewardText : ""
                                                      }
                                                      color={{
                                                        textColor: media.colors.appAreaTxtColor,
                                                        mcvTextColor: media.colors.mcvTxtColor,
                                                      }}
                                                      timeSale={null}
                                                      bonus={null}
                                                      basePoint={null}
                                                      isPercentage={false}
                                                    />
                                                  ))
                                              ) : (
                                                <div
                                                  className={Styles["no-challenge"]}
                                                  style={{ color: media.colors.appAreaTxtColor }}
                                                  data-testid="noChallengeItems"
                                                >
                                                  チャレンジ中の案件はありません
                                                </div>
                                              )}
                                            </HorizontalBox>
                                          </div>
                                        )}
                                        <div
                                          className={clsx(
                                            Styles["list-container"],
                                            "swipe-disable"
                                          )}
                                          data-position={3.6}
                                          // 横スクロール中のタブ切り替えを制御
                                          onTouchMove={() => {
                                            setIsHorizontalScroll(true);
                                          }}
                                          onTouchEnd={() => {
                                            setIsHorizontalScroll(false);
                                          }}
                                        >
                                          <HorizontalBox
                                            isMore={PickupOffers[0].offers.length > 0}
                                            type="easy"
                                            style={{
                                              color:
                                                media.offerwallType === offerType.LINE_MANGA &&
                                                media.colors.appAreaTxtColor,
                                            }}
                                            title={PickupOffers[0].name}
                                            to={{
                                              pathname: `${defaultPath.INDEX}/pickup`,
                                              search: `${location.search}&position=3.6`,
                                              state: {
                                                display: PickupOffers[0].name,
                                                referrer: {
                                                  pathname: location.pathname,
                                                  search: location.search,
                                                },
                                              },
                                            }}
                                          >
                                            {PickupOffers[0].offers?.length > 0 &&
                                              PickupOffers[0].offers
                                                .filter((_, i) => i < 10)
                                                ?.map((data, index) => {
                                                  const targetAbTestSurvey =
                                                    data.isSurvey && abTestSurveys
                                                      ? getNextSurveyForOffer(
                                                          data.id,
                                                          abTestSurveys
                                                        )
                                                      : null;
                                                  return data.isSurvey ? (
                                                    targetAbTestSurvey && (
                                                      <ExternalOfferBlock
                                                        key={index}
                                                        href={`${
                                                          targetAbTestSurvey.redirectSt
                                                        }&position=${index + 1}`}
                                                        iconUrl={targetAbTestSurvey.iconUrl}
                                                        superSale={getSuperSaleIntegration(
                                                          {
                                                            ...media?.superSale,
                                                            point:
                                                              media?.superSale?.pointMultiplier,
                                                          },
                                                          media?.superSaleSpecifiedOffers,
                                                          data.id
                                                        )}
                                                        timeSale={null}
                                                        bonus={null}
                                                        basePoint={targetAbTestSurvey.basePoint}
                                                        actualPoint={targetAbTestSurvey.actualPoint}
                                                        pointIconUrl={media.pointIconUrl}
                                                        isSur={false}
                                                        text=""
                                                        color={{
                                                          textColor: media.colors.appAreaTxtColor,
                                                          mcvTextColor: media.colors.mcvTxtColor,
                                                        }}
                                                        isPercentage={false}
                                                      />
                                                    )
                                                  ) : (
                                                    <OfferBlock
                                                      key={index}
                                                      to={{
                                                        pathname: `${defaultPath.INDEX}/detail`,
                                                        search: `${location.search}&offer=${
                                                          data.id
                                                        }&tab=${
                                                          media.offerwallType ===
                                                          offerType.LINE_MANGA
                                                            ? 1
                                                            : 4
                                                        }&position=3.6&horizontalposition=${
                                                          index + 1
                                                        }&linkfrom=ow_hr1`,
                                                        state: {
                                                          previousPath: "",
                                                          referrer: {
                                                            pathname: location.pathname,
                                                            search: location.search,
                                                          },
                                                        },
                                                      }}
                                                      iconUrl={data.iconUrl}
                                                      superSale={getSuperSaleIntegration(
                                                        {
                                                          ...media?.superSale,
                                                          point: media?.superSale?.pointMultiplier,
                                                        },
                                                        media?.superSaleSpecifiedOffers,
                                                        data.id
                                                      )}
                                                      timeSale={{
                                                        startAt:
                                                          data.conversionPoints.slice(-1)[0]
                                                            ?.timeSale?.startAt,
                                                        endAt:
                                                          data.conversionPoints.slice(-1)[0]
                                                            ?.timeSale?.endAt,
                                                      }}
                                                      bonus={{
                                                        point:
                                                          data.conversionPoints.slice(-1)[0]?.bonus
                                                            ?.point,
                                                        startAt:
                                                          data.conversionPoints.slice(-1)[0]?.bonus
                                                            ?.startAt,
                                                        endAt:
                                                          data.conversionPoints.slice(-1)[0]?.bonus
                                                            ?.endAt,
                                                      }}
                                                      basePoint={data.basePoint}
                                                      actualPoint={data.actualPoint}
                                                      pointIconUrl={media.pointIconUrl}
                                                      isSur={data.isSur}
                                                      text={
                                                        data.isSur ? media.stepUpRewardText : ""
                                                      }
                                                      color={{
                                                        textColor: media.colors.appAreaTxtColor,
                                                        mcvTextColor: media.colors.mcvTxtColor,
                                                      }}
                                                      isPercentage={false}
                                                    />
                                                  );
                                                })}
                                          </HorizontalBox>
                                        </div>
                                        <div
                                          className={clsx(
                                            Styles["list-container"],
                                            Styles["list-container-last"],
                                            "swipe-disable"
                                          )}
                                          data-position={3.9}
                                          // 横スクロール中のタブ切り替えを制御
                                          onTouchMove={() => {
                                            setIsHorizontalScroll(true);
                                          }}
                                          onTouchEnd={() => {
                                            setIsHorizontalScroll(false);
                                          }}
                                        >
                                          <HorizontalBox
                                            isMore={
                                              PickupOffers[1].offers &&
                                              PickupOffers[1].offers.length > 0
                                            }
                                            type="many"
                                            style={{
                                              color:
                                                media.offerwallType === offerType.LINE_MANGA &&
                                                media.colors.appAreaTxtColor,
                                            }}
                                            title={PickupOffers[1].name}
                                            to={{
                                              pathname: `${defaultPath.INDEX}/pickup`,
                                              search: `${location.search}&position=3.9`,
                                              state: {
                                                display: PickupOffers[1].name,
                                                previousPath: "",
                                                referrer: {
                                                  pathname: location.pathname,
                                                  search: location.search,
                                                },
                                              },
                                            }}
                                          >
                                            {PickupOffers[1].offers?.length > 0 &&
                                              PickupOffers[1].offers
                                                .filter((_, i) => i < 10)
                                                .map((data, index) => (
                                                  <OfferBlock
                                                    key={index}
                                                    to={{
                                                      pathname: `${defaultPath.INDEX}/detail`,
                                                      search: `${location.search}&offer=${
                                                        data.id
                                                      }&tab=5&position=3.9&horizontalposition=${
                                                        index + 1
                                                      }&linkfrom=ow_hr2`,
                                                      state: {
                                                        display: PickupOffers[1].name,
                                                        previousPath: "",
                                                        referrer: {
                                                          pathname: location.pathname,
                                                          search: location.search,
                                                        },
                                                      },
                                                    }}
                                                    iconUrl={data.iconUrl}
                                                    superSale={getSuperSaleIntegration(
                                                      {
                                                        ...media?.superSale,
                                                        point: media?.superSale?.pointMultiplier,
                                                      },
                                                      media?.superSaleSpecifiedOffers,
                                                      data.id
                                                    )}
                                                    timeSale={{
                                                      startAt:
                                                        data.conversionPoints.slice(-1)[0]?.timeSale
                                                          ?.startAt,
                                                      endAt:
                                                        data.conversionPoints.slice(-1)[0]?.timeSale
                                                          ?.endAt,
                                                    }}
                                                    bonus={{
                                                      point:
                                                        data.conversionPoints.slice(-1)[0]?.bonus
                                                          ?.point,
                                                      startAt:
                                                        data.conversionPoints.slice(-1)[0]?.bonus
                                                          ?.startAt,
                                                      endAt:
                                                        data.conversionPoints.slice(-1)[0]?.bonus
                                                          ?.endAt,
                                                    }}
                                                    basePoint={data.basePoint}
                                                    actualPoint={data.actualPoint}
                                                    pointIconUrl={media.pointIconUrl}
                                                    isSur={data.isSur}
                                                    text={data.isSur ? media.stepUpRewardText : ""}
                                                    color={{
                                                      textColor: media.colors.appAreaTxtColor,
                                                      mcvTextColor: media.colors.mcvTxtColor,
                                                    }}
                                                    isPercentage={false}
                                                  />
                                                ))}
                                          </HorizontalBox>
                                        </div>
                                      </div>
                                      {media.surveyDisplayType === surveyDisplayType.TAB ? (
                                        <>
                                          {value &&
                                            value.offers.slice(2).map((offer, i) => (
                                              <OfferCard
                                                color={{
                                                  appAreaBgColor: media.colors.appAreaBgColor,
                                                  appAreaTxtColor: media.colors.appAreaTxtColor,
                                                  expointTxtColor: media.colors.expointTxtColor,
                                                  cvConditionColor: media.colors.cvConditionColor,
                                                  dlBtnBgColor: media.colors.dlBtnBgColor,
                                                  dlBtnTxtColor: media.colors.dlBtnTxtColor,
                                                }}
                                                pointIconUrl={media.pointIconUrl}
                                                videoIconUrl={media.videoIconUrl}
                                                isVideo={offer.isVideo}
                                                superSale={getSuperSaleIntegration(
                                                  {
                                                    ...media?.superSale,
                                                    point: media?.superSale?.pointMultiplier,
                                                  },
                                                  media?.superSaleSpecifiedOffers,
                                                  offer.id
                                                )}
                                                isButton={!!media}
                                                timeSale={{
                                                  startAt:
                                                    offer.conversionPoints.slice(-1)[0]?.timeSale
                                                      ?.startAt,
                                                  endAt:
                                                    offer.conversionPoints.slice(-1)[0]?.timeSale
                                                      ?.endAt,
                                                }}
                                                bonus={{
                                                  point:
                                                    offer.conversionPoints.slice(-1)[0]?.bonus
                                                      ?.point,
                                                  startAt:
                                                    offer.conversionPoints.slice(-1)[0]?.bonus
                                                      ?.startAt,
                                                  endAt:
                                                    offer.conversionPoints.slice(-1)[0]?.bonus
                                                      ?.endAt,
                                                }}
                                                dlBtnName={
                                                  offer.advertisementId
                                                    ? media.dlBtnNameApp
                                                    : media.dlBtnNameWeb
                                                }
                                                basePoint={offer.basePoint}
                                                actualPoint={offer.actualPoint}
                                                isSur={offer.isSur}
                                                displayName={offer?.displayName}
                                                cvCondition={offer?.cvCondition}
                                                listImage={offer.listImageUrl}
                                                isPercentage={offer.isPercentage}
                                                search={{
                                                  offerId: offer.id,
                                                  position: i + 1 + 2,
                                                  linkFrom: "ow",
                                                }}
                                                isPickUp={isPickUp}
                                                pointUnit={media.pointUnit}
                                                offerwallType={media.offerwallType}
                                                tutorialOffer={{
                                                  isTutorialOffer: offer.isTutorialOffer,
                                                  tutorialOfferActionUrl:
                                                    offer.tutorialOfferActionUrl,
                                                }}
                                                key={i}
                                              />
                                            ))}
                                        </>
                                      ) : (
                                        <>
                                          {value &&
                                            value.offers.slice(1).map((offer, i) => (
                                              <OfferCard
                                                color={{
                                                  appAreaBgColor: media.colors.appAreaBgColor,
                                                  appAreaTxtColor: media.colors.appAreaTxtColor,
                                                  expointTxtColor: media.colors.expointTxtColor,
                                                  cvConditionColor: media.colors.cvConditionColor,
                                                  dlBtnBgColor: media.colors.dlBtnBgColor,
                                                  dlBtnTxtColor: media.colors.dlBtnTxtColor,
                                                }}
                                                pointIconUrl={media.pointIconUrl}
                                                videoIconUrl={media.videoIconUrl}
                                                isVideo={offer.isVideo}
                                                superSale={getSuperSaleIntegration(
                                                  {
                                                    ...media?.superSale,
                                                    point: media?.superSale?.pointMultiplier,
                                                  },
                                                  media?.superSaleSpecifiedOffers,
                                                  offer.id
                                                )}
                                                isButton={!!media}
                                                timeSale={{
                                                  startAt:
                                                    offer.conversionPoints.slice(-1)[0]?.timeSale
                                                      ?.startAt,
                                                  endAt:
                                                    offer.conversionPoints.slice(-1)[0]?.timeSale
                                                      ?.endAt,
                                                }}
                                                bonus={{
                                                  point:
                                                    offer.conversionPoints.slice(-1)[0]?.bonus
                                                      ?.point,
                                                  startAt:
                                                    offer.conversionPoints.slice(-1)[0]?.bonus
                                                      ?.startAt,
                                                  endAt:
                                                    offer.conversionPoints.slice(-1)[0]?.bonus
                                                      ?.endAt,
                                                }}
                                                dlBtnName={
                                                  offer.advertisementId
                                                    ? media.dlBtnNameApp
                                                    : media.dlBtnNameWeb
                                                }
                                                basePoint={offer.basePoint}
                                                actualPoint={offer.actualPoint}
                                                isSur={offer.isSur}
                                                displayName={offer?.displayName}
                                                cvCondition={offer?.cvCondition}
                                                listImage={offer.listImageUrl}
                                                isPercentage={offer.isPercentage}
                                                search={{
                                                  offerId: offer.id,
                                                  position: i + 1 + 2,
                                                  linkFrom: "ow",
                                                }}
                                                isPickUp={isPickUp}
                                                pointUnit={media.pointUnit}
                                                offerwallType={media.offerwallType}
                                                tutorialOffer={{
                                                  isTutorialOffer: offer.isTutorialOffer,
                                                  tutorialOfferActionUrl:
                                                    offer.tutorialOfferActionUrl,
                                                }}
                                                key={i}
                                              />
                                            ))}
                                        </>
                                      )}
                                      <div ref={setPagenateRef} />
                                    </>
                                  );
                                case DisplayTabType.Regular:
                                  return (
                                    <>
                                      {media.offerwallType === offerType.LINE_MANGA ||
                                      media.offerwallType === offerType.PICCOMA_APP ||
                                      media.offerwallType === offerType.PICCOMA_WEB ||
                                      media.offerwallType === offerType.PICKUP ? (
                                        <PikkomaOffersList
                                          offers={value.offers}
                                          abTestSurveys={abTestSurveys}
                                          superSale={{
                                            startAt: media?.primarySuperSaleStartAt,
                                            endAt: media?.primarySuperSaleEndAt,
                                            point: media?.primarySuperSalePointMultiplier,
                                          }}
                                          superSaleSpecifiedOffers={media?.superSaleSpecifiedOffers}
                                          color={{
                                            appAreaTxtColor: media.colors.appAreaTxtColor,
                                            appAreaBgColor: media.colors.appAreaBgColor,
                                            mcvTxtColor: media.colors.mcvTxtColor,
                                            cvConditionColor: media.colors.cvConditionColor,
                                            clockTxtColor: media.colors.expointTxtColor,
                                            expointBgColor: media.colors.expointBgColor,
                                            expointTxtColor: media.colors.expointTxtColor,
                                            mcvBgColor: media.colors.mcvBgColor,
                                            dlBtnBgColor: media.colors.dlBtnBgColor,
                                            dlBtnTxtColor: media.colors.dlBtnTxtColor,
                                          }}
                                          texts={{
                                            preRegistText: media.preRegistText,
                                            stepUpRewardText: media.stepUpRewardText,
                                          }}
                                          pointIconUrl={media.pointIconUrl}
                                          pointUnit={media.pointUnit}
                                          recommendIconUrl={media.recommendIconUrl}
                                          videoIconUrl={media.videoIconUrl}
                                          linkFrom="ow"
                                          BannerElement={BannerElement}
                                          offerwallType={media.offerwallType}
                                        />
                                      ) : (
                                        <OffersList
                                          offers={value.offers}
                                          abTestSurveys={abTestSurveys}
                                          color={{
                                            appAreaBgColor: media.colors.appAreaBgColor,
                                            expointBgColor: media.colors.expointBgColor,
                                            expointTxtColor: media.colors.expointTxtColor,
                                            mcvBgColor: media.colors.mcvBgColor,
                                            mcvTxtColor: media.colors.mcvTxtColor,
                                            appAreaTxtColor: media.colors.appAreaTxtColor,
                                            cvConditionColor: media.colors.cvConditionColor,
                                            dlBtnBgColor: media.colors.dlBtnBgColor,
                                            dlBtnTxtColor: media.colors.dlBtnTxtColor,
                                          }}
                                          surTxt={media.stepUpRewardText}
                                          prTxt={media.preRegistText}
                                          pointIconUrl={media.pointIconUrl}
                                          pointUnit={media.pointUnit}
                                          recommendIconUrl={media.recommendIconUrl}
                                          videoIconUrl={media.videoIconUrl}
                                          superSale={{
                                            startAt: media.primarySuperSaleStartAt,
                                            endAt: media.primarySuperSaleEndAt,
                                            point: media.primarySuperSalePointMultiplier,
                                          }}
                                          superSaleSpecifiedOffers={media.superSaleSpecifiedOffers}
                                          linkFrom="ow"
                                          displayTabType={value.tabDisplayType}
                                          bannerImage={media.bannerImageUrl}
                                          tab={tab}
                                          surveyType={media.surveyDisplayType}
                                          offerwallType={media.offerwallType}
                                        />
                                      )}
                                      <div ref={setPagenateRef} />
                                    </>
                                  );
                                case DisplayTabType.Survey: {
                                  return (
                                    <>
                                      {media.offerwallType === offerType.MANGA ||
                                      media.offerwallType === offerType.TRIP_MILE ? (
                                        <SurveyList
                                          color={{
                                            appAreaBgColor: media.colors.appAreaBgColor,
                                            expointBgColor: media.colors.expointBgColor,
                                            expointTxtColor: media.colors.expointTxtColor,
                                            mcvBgColor: media.colors.mcvBgColor,
                                            mcvTxtColor: media.colors.mcvTxtColor,
                                            appAreaTxtColor: media.colors.appAreaTxtColor,
                                            cvConditionColor: media.colors.cvConditionColor,
                                            dlBtnBgColor: media.colors.dlBtnBgColor,
                                            dlBtnTxtColor: media.colors.dlBtnTxtColor,
                                          }}
                                          pointIconUrl={media.pointIconUrl}
                                          pointUnit={media.pointUnit}
                                          superSale={{
                                            startAt: media.primarySuperSaleStartAt,
                                            endAt: media.primarySuperSaleEndAt,
                                            point: media.primarySuperSalePointMultiplier,
                                          }}
                                          search={{
                                            linkFrom: "ow",
                                            tab: "8",
                                          }}
                                          offerwallType={media.offerwallType}
                                        />
                                      ) : (
                                        <>
                                          {(media.offerwallType === offerType.PICCOMA_APP ||
                                            media.offerwallType === offerType.PICCOMA_WEB) && (
                                            <>{BannerElement}</>
                                          )}
                                          <OthersSurveyList
                                            color={{
                                              appAreaBgColor: media.colors.appAreaBgColor,
                                              expointBgColor: media.colors.expointBgColor,
                                              expointTxtColor: media.colors.expointTxtColor,
                                              mcvBgColor: media.colors.mcvBgColor,
                                              mcvTxtColor: media.colors.mcvTxtColor,
                                              appAreaTxtColor: media.colors.appAreaTxtColor,
                                              cvConditionColor: media.colors.cvConditionColor,
                                              dlBtnBgColor: media.colors.dlBtnBgColor,
                                              dlBtnTxtColor: media.colors.dlBtnTxtColor,
                                            }}
                                            pointIconUrl={media.pointIconUrl}
                                            pointUnit={media.pointUnit}
                                            superSale={{
                                              startAt: media.primarySuperSaleStartAt,
                                              endAt: media.primarySuperSaleEndAt,
                                              point: media.primarySuperSalePointMultiplier,
                                            }}
                                            search={{
                                              linkFrom: "ow",
                                              tab: "8",
                                            }}
                                            offerwallType={media.offerwallType}
                                          />
                                        </>
                                      )}
                                      <div ref={setPagenateRef} />
                                    </>
                                  );
                                }
                                case DisplayTabType.FeatureBox:
                                case DisplayTabType.FeatureIcon:
                                case DisplayTabType.FeatureSmallBox:
                                  return (
                                    <>
                                      <OffersListAB
                                        offers={value.offers}
                                        color={{
                                          appAreaBgColor: media.colors.appAreaBgColor,
                                          expointBgColor: media.colors.expointBgColor,
                                          expointTxtColor: media.colors.expointTxtColor,
                                          mcvBgColor: media.colors.mcvBgColor,
                                          mcvTxtColor: media.colors.mcvTxtColor,
                                          appAreaTxtColor: media.colors.appAreaTxtColor,
                                          cvConditionColor: media.colors.cvConditionColor,
                                          dlBtnBgColor: media.colors.dlBtnBgColor,
                                          dlBtnTxtColor: media.colors.dlBtnTxtColor,
                                        }}
                                        surTxt={media.stepUpRewardText}
                                        prTxt={media.preRegistText}
                                        pointIconUrl={media.pointIconUrl}
                                        pointUnit={media.pointUnit}
                                        recommendIconUrl={media.recommendIconUrl}
                                        superSale={{
                                          startAt: media.primarySuperSaleStartAt,
                                          endAt: media.primarySuperSaleEndAt,
                                          point: media.primarySuperSalePointMultiplier,
                                        }}
                                        superSaleSpecifiedOffers={media.superSaleSpecifiedOffers}
                                        linkFrom="ow"
                                        displayTabType={value.tabDisplayType}
                                      />
                                      <div ref={setPagenateRef} />
                                    </>
                                  );
                                default:
                                  return <></>;
                              }
                            })()}
                            <Footer
                              color={{
                                bgColor: media?.colors?.titleBgColor,
                                txtColor: media?.colors?.titleTxtColor,
                              }}
                              menus={media.menus}
                              text={media.footerText}
                              offerwallType={media.offerwallType}
                              surveys={surveys}
                              privacyPolicyUrl={media.privacyPolicyLinkUrl}
                              cookiePolicyUrl={media.cookiePolicyLinkUrl}
                              customerSupportEmail={media.anotherCustomerSupportEmailAddress}
                            />
                          </div>
                        )
                    ),
                  ]}
                />
              </div>
            </div>
            {media.superSaleBannerType === superSaleType.TIMER && (
              <div className={Styles.currencyClockContainer}>
                <Clock
                  saleType="currency"
                  currencySaleBonus={media.primarySuperSalePointMultiplier}
                  bgColor={media.colors.timerBgColor}
                  txtColor={media.colors.timerTxtColor}
                  iconColor={media.colors.timerIconColor}
                  ratioColor={media.colors.timerRatioColor}
                  opacity={media.colors.timerOpacity}
                  saleStart={media.primarySuperSaleStartAt}
                  saleEnd={media.primarySuperSaleEndAt}
                  bonusStart={null}
                  bonusEnd={null}
                  superSaleSpecifiedOfferStart={null}
                  superSaleSpecifiedOfferEnd={null}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
);

export default memo(MainContents);
