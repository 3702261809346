import React from "react";
import Styles from "SkyflagFrontendOfferwall/stylesheets/shared/OfferIcon.module.scss";
import StylesAB from "SkyflagFrontendOfferwall/stylesheets/shared/OfferIconAB.module.scss";
import CommonStyles from "SkyflagFrontendOfferwall/stylesheets/CommonStyles.module.scss";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { selectRouter } from "store/selector";
import { useSelector } from "react-redux";
import { disassemblyPath } from "modules/url";
import { DisplayTabType } from "utiles/offer";

interface Props {
  src: string;
  bgColor: string;
  txtColor: string;
  isSUR: boolean;
  surTxt: string;
  prTxt: string;
  to?: string;
  displayTabType?: number;
}

export const OfferIcon: React.FC<Props> = (props) => {
  const { location } = useSelector(selectRouter);
  const { isSUR, surTxt, to } = props;

  const visibleStyle: React.CSSProperties = {
    backgroundColor: props.bgColor,
    color: props.txtColor,
  };
  const invisibleStyle: React.CSSProperties = {
    display: "none",
  };
  const selectedStyles =
    props.displayTabType === DisplayTabType.FeatureBox ||
    props.displayTabType === DisplayTabType.FeatureIcon
      ? StylesAB
      : Styles;
  const typeText = (): string | null => {
    switch (true) {
      case isSUR:
        return surTxt;
      default:
        return null;
    }
  };

  return (
    <div className={selectedStyles.container}>
      {to ? (
        <Link
          to={{
            ...disassemblyPath(to),
            state: { display: location.state?.display, referrer: location },
          }}
        >
          <img src={props.src} alt="アイコン" />
        </Link>
      ) : (
        <img src={props.src} alt="アイコン" />
      )}
      <div
        className={clsx(selectedStyles.footer, CommonStyles.center)}
        style={isSUR ? visibleStyle : invisibleStyle}
      >
        <span className={selectedStyles.footer_text} data-testid="surText">
          {typeText()}
        </span>
      </div>
    </div>
  );
};
