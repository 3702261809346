import React, { useEffect } from "react";
import { useIsSale } from "modules/useIsSale";
import Styles from "stylesheets/shared/Clock.module.scss";
import FontStyles from "stylesheets/FontStyles.module.scss";
import { Chips } from "./Chips";
import clsx from "clsx";

export interface Props {
  saleType: string;
  currencySaleBonus?: number;
  bgColor?: string;
  txtColor: string;
  iconColor?: string;
  ratioColor?: string;
  opacity?: string | null;
  saleStart: Date;
  saleEnd: Date;
  bonusStart: Date;
  bonusEnd: Date;
  superSaleSpecifiedOfferStart: Date;
  superSaleSpecifiedOfferEnd: Date;
  isDisplaySmall?: boolean;
  isIncreaseIconHide?: boolean;
}

type TimeDifference = {
  hours: number;
  minutes: number;
  seconds: number;
};

export const Clock: React.FC<Props> = (props) => {
  // セール時間外ではnullを返すコンポーネントになる
  const [
    now,
    isInCurrencySale,
    ,
    isInTimeSale,
    confirmInTimeSale,
    isInBonus,
    confirmInBonus,
    isInSuperSaleSpecifiedOffer,
    confirmInSuperSaleSpecifiedOffer,
    setDefault,
  ] = useIsSale();
  const {
    saleType,
    txtColor,
    opacity = 0.9,
    isDisplaySmall = false,
    isIncreaseIconHide = false,
  } = props; // タイムセールかカレンシーセールかボーナスか

  useEffect(() => {
    // 現在が更新される度にセール中かどうかを確認する
    confirmInTimeSale(props.saleStart, props.saleEnd);
    confirmInBonus(props.bonusStart, props.bonusEnd);
    confirmInSuperSaleSpecifiedOffer(
      props.superSaleSpecifiedOfferStart,
      props.superSaleSpecifiedOfferEnd
    );
    return () => {
      // コンポーネントアンマウント時の処理
      setDefault();
    };
  }, [now]);

  if (!isInCurrencySale && !isInTimeSale && !isInBonus && !isInSuperSaleSpecifiedOffer) return null; // セール中でなければ非表示に

  const calculateTimeDifference = (start: Date, end: Date): TimeDifference => {
    const diff = end.getTime() - start.getTime();
    const seconds = Math.floor(diff / 1000) % 60;
    const minutes = Math.floor(diff / 1000 / 60) % 60;
    const hours = Math.floor(diff / 1000 / 60 / 60);

    return {
      hours,
      minutes,
      seconds,
    };
  };

  // 残り時間表示
  const saleEnd: Date = new Date(props.saleEnd);
  const salesTimeValues = calculateTimeDifference(now, saleEnd);
  const cSaleBonus = props.currencySaleBonus; // カレンシーセールである場合の倍率
  const bonusEnd: Date = new Date(props.bonusEnd);
  const bonusEndDate = bonusEnd.toLocaleDateString("ja-JP", {
    month: "long",
    day: "numeric",
    timeZone: "Asia/Tokyo",
  });
  const superSaleSpecifiedOfferEnd: Date = new Date(props.superSaleSpecifiedOfferEnd);
  const superSaleSpecifiedTimeValues = calculateTimeDifference(now, superSaleSpecifiedOfferEnd);

  return (
    <div
      className={clsx(
        Styles.container,
        isDisplaySmall ? FontStyles.small : FontStyles.large,
        saleType === "time" ? Styles.timeSale : Styles.currencySale
      )}
      style={{
        backgroundColor: props.bgColor,
        color: props.txtColor,
        opacity: !opacity ? 0.9 : Number(opacity),
      }}
    >
      <div
        className={Styles.bonusIcon}
        style={{
          display: saleType === "time" ? "none" : "block",
          backgroundColor: props.iconColor,
          color: props.ratioColor,
        }}
      >
        <span>&times;{cSaleBonus}</span>
      </div>
      <div className={Styles.timer}>
        {!isIncreaseIconHide && (
          <div className={Styles.icon}>
            <Chips
              style={{
                background: txtColor,
              }}
              type="primary"
              text="増量中"
            />
          </div>
        )}
        <span className={Styles.timer_remainTime} data-testid="remainTime">
          {isInTimeSale ? (
            <>
              残り{saleType === "currency" && "時間"}&nbsp;{salesTimeValues.hours}&nbsp;時間&nbsp;
              {salesTimeValues.minutes}&nbsp;分&nbsp;
              {salesTimeValues.seconds}&nbsp;秒
            </>
          ) : isInBonus ? (
            <>{bonusEndDate}&nbsp;まで</>
          ) : isInSuperSaleSpecifiedOffer ? (
            <>
              残り{saleType === "currency" && "時間"}&nbsp;{superSaleSpecifiedTimeValues.hours}
              &nbsp;時間&nbsp;{superSaleSpecifiedTimeValues.minutes}&nbsp;分&nbsp;
              {superSaleSpecifiedTimeValues.seconds}&nbsp;秒
            </>
          ) : null}
        </span>
      </div>
    </div>
  );
};
