import { Expose } from "class-transformer";

export class SuperSaleSpecifiedOffer {
  @Expose({ name: "offerId" })
  offerId: number;

  @Expose({ name: "pointMultiplier" })
  pointMultiplier: number;

  @Expose({ name: "startAt" })
  startAt: Date;

  @Expose({ name: "endAt" })
  endAt: Date;
}
