import React from "react";
import { OfferIcon } from "SkyflagFrontendOfferwall/components/shared/OfferIcon";
import { Clock } from "SkyflagFrontendOfferwall/components/shared/Clock";
import {
  PointResult,
  Props as PointResultProps,
} from "SkyflagFrontendOfferwall/components/index/PointResult";
import { Button } from "SkyflagFrontendOfferwall/components/common/Button";
import stylesResponsive from "SkyflagFrontendOfferwall/stylesheets/index/OffersListItem.module.scss";
import stylesPortrait from "SkyflagFrontendOfferwall/stylesheets/index/OffersListItemPortrait.module.scss";
import CommonStyles from "SkyflagFrontendOfferwall/stylesheets/CommonStyles.module.scss";
import FontStyles from "SkyflagFrontendOfferwall/stylesheets/FontStyles.module.scss";
import clsx from "clsx";

interface Color {
  appAreaBgColor: string;
  expointBgColor: string;
  expointTxtColor: string;
  mcvBgColor: string;
  mcvTxtColor: string;
  appAreaTxtColor: string;
  cvConditionColor: string;
  dlBtnBgColor: string;
  dlBtnTxtColor: string;
}

export interface Props extends PointResultProps {
  color: Color;
  surTxt: string;
  prTxt: string;
  recommendIconUrl: string;
  videoIconUrl?: string;
  position: number;
  isRecommended: boolean;
  isVideo?: boolean;
  iconUrl: string;
  displayName: string;
  cvCondition: string;
  isPercentage: boolean;
  displayTabType: number;
  isTripMile: boolean;
}

export const OffersListItem = React.memo(
  ({
    color,
    surTxt,
    prTxt,
    recommendIconUrl,
    videoIconUrl,
    position,
    iconUrl,
    isRecommended,
    isVideo = false,
    displayName,
    cvCondition,
    displayTabType,
    isTripMile,
    ...pointResultProps
  }: Props) => {
    const Styles = isTripMile ? stylesPortrait : stylesResponsive;

    return (
      <div
        className={Styles.wrapper}
        style={{ backgroundColor: color.appAreaBgColor }}
        data-position={position}
      >
        <div className={Styles.clock}>
          {(pointResultProps.timeSale?.point > 0 ||
            pointResultProps.bonus?.point > 0 ||
            pointResultProps.superSale?.isSpecifiedOffer) && (
            <Clock
              saleType="time"
              bgColor={color.expointBgColor}
              txtColor={color.expointTxtColor}
              saleStart={pointResultProps.timeSale?.startAt}
              saleEnd={pointResultProps.timeSale?.endAt}
              bonusStart={pointResultProps.bonus?.startAt}
              bonusEnd={pointResultProps.bonus?.endAt}
              superSaleSpecifiedOfferStart={
                pointResultProps.superSale?.isSpecifiedOffer
                  ? pointResultProps.superSale?.startAt
                  : null
              }
              superSaleSpecifiedOfferEnd={
                pointResultProps.superSale?.isSpecifiedOffer
                  ? pointResultProps.superSale?.endAt
                  : null
              }
            />
          )}
        </div>
        <div className={Styles.offer}>
          <OfferIcon
            src={iconUrl}
            bgColor={color.mcvBgColor}
            txtColor={color.mcvTxtColor}
            isSUR={pointResultProps.isSur}
            surTxt={surTxt}
            prTxt={prTxt}
            displayTabType={displayTabType}
          />
          <div className={Styles.offer_content}>
            <div className={Styles.offer_content_upper}>
              <div className={Styles.info}>
                <h3 className={Styles.info_name} style={{ color: color.appAreaTxtColor }}>
                  {displayName}
                </h3>
                <div
                  className={clsx(CommonStyles.cvCondition, FontStyles.small)}
                  style={{ color: color.cvConditionColor }}
                >
                  {cvCondition}
                </div>
              </div>
            </div>
            {isVideo && videoIconUrl ? (
              <div className={Styles.recommendIcon}>
                <img src={videoIconUrl} alt="ビデオ" />
              </div>
            ) : (
              isRecommended &&
              recommendIconUrl && (
                <div className={Styles.recommendIcon}>
                  <img src={recommendIconUrl} alt="オススメ" />
                </div>
              )
            )}
            <Button
              className={
                isTripMile ? Styles.offer_content_tripMileButton : Styles.offer_content_button
              }
              title={<PointResult {...pointResultProps} />}
              color={{
                bgColor: color.dlBtnBgColor,
                txtColor: color.dlBtnTxtColor,
              }}
              tag="button"
              type="primary"
            />
          </div>
        </div>
      </div>
    );
  }
);
