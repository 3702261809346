import { Expose, Type } from "class-transformer";
import { Banners, Menus, Colors, SuperSales } from "./index";
import { offerType } from "utiles/media";
import { offerwallType } from "types/offerwallType";
import { superSaleBannerType } from "types/superSaleBannerType";
import { SuperSaleSpecifiedOffer } from "./superSaleSpecifiedOffer";

export class Media {
  id: number;

  @Expose({ name: "name" })
  name: string;

  @Expose({ name: "redirectType" })
  redirectType: number;

  @Expose({ name: "offerwallType" })
  offerwallType: offerwallType;

  @Expose({ name: "isBannerTabHidden" })
  isBannerTabHidden: boolean;

  @Expose({ name: "backIconHidden" })
  backIconHidden: boolean;

  @Expose({ name: "clickMethod" })
  clickMethod?: string;

  @Expose({ name: "isCloseIconHidden" })
  isCloseIconHidden: boolean;

  @Expose({ name: "closeLink" })
  closeLink: string;

  @Expose({ name: "dlBtnNameApp" })
  dlBtnNameApp: string;

  @Expose({ name: "dlBtnNamePreRegist" })
  dlBtnNamePreRegist: string;

  @Expose({ name: "dlBtnNameWeb" })
  dlBtnNameWeb: string;

  @Expose({ name: "dlBtnNameVideoApp" })
  dlBtnNameVideoApp: string;

  @Expose({ name: "dlBtnNameVideoWeb" })
  dlBtnNameVideoWeb: string;

  @Expose({ name: "footerText" })
  footerText: string;

  @Expose({ name: "pointIconUrl" })
  pointIconUrl: string;

  @Expose({ name: "pointUnit" })
  pointUnit: string;

  @Expose({ name: "preRegistEmailAnnounce" })
  preRegistEmailAnnounce: string;

  @Expose({ name: "preRegistText" })
  preRegistText: string;

  @Expose({ name: "recommendIconUrl" })
  recommendIconUrl: string;

  @Expose({ name: "stepUpRewardText" })
  stepUpRewardText: string;

  @Expose({ name: "tabOtherName" })
  tabOtherName: string;

  @Expose({ name: "isIdfaRequired" })
  isIdfaRequired: boolean;

  @Expose({ name: "wallIconUrl" })
  wallIconUrl: string;

  @Expose({ name: "wallTitle" })
  wallTitle: string;

  @Expose({ name: "isWallTitleHidden" })
  isWallTitleHidden: boolean;

  @Expose({ name: "superSaleType" })
  superSaleBannerType: superSaleBannerType;

  @Expose({ name: "banners" })
  @Type(() => Banners)
  banners?: Banners[];

  @Expose({ name: "menus" })
  @Type(() => Menus)
  menus?: Menus[];

  @Expose({ name: "color" })
  @Type(() => Colors)
  colors: Colors;

  @Expose({ name: "superSale" })
  @Type(() => SuperSales)
  superSale?: SuperSales;

  @Expose({ name: "superSaleSpecifiedOffers" })
  @Type(() => SuperSaleSpecifiedOffer)
  superSaleSpecifiedOffers?: SuperSaleSpecifiedOffer[];

  @Expose({ name: "isLoginRequired" })
  isLoginRequired: boolean;

  @Expose({ name: "googleAnalyticsTrackingId" })
  googleAnalyticsTrackingId?: string;

  @Expose({ name: "redirectPageUrl" })
  redirectPageUrl?: string;

  @Expose({ name: "surveyContactUrl" })
  surveyContactUrl?: string;

  @Expose({ name: "changeMonitorAttributeUrl" })
  changeMonitorAttributeUrl?: string;

  @Expose({ name: "surveyDisplayType" })
  surveyDisplayType: number;

  @Expose({ name: "bannerImageUrl" })
  bannerImageUrl?: string;

  @Expose({ name: "videoIconUrl" })
  videoIconUrl?: string;

  @Expose({ name: "privacyPolicyLinkUrl" })
  privacyPolicyLinkUrl?: string;

  @Expose({ name: "cookiePolicyLinkUrl" })
  cookiePolicyLinkUrl?: string;

  @Expose({ name: "anotherCustomerSupportEmailAddress" })
  anotherCustomerSupportEmailAddress?: string;

  @Expose({ name: "advertisementCommonCautionText" })
  advertisementCommonCautionText?: string;

  get primarySuperSalePointMultiplier() {
    return this.superSale?.pointMultiplier;
  }

  get primarySuperSaleStartAt() {
    return this.superSale?.startAt;
  }

  get primarySuperSaleEndAt() {
    return this.superSale?.endAt;
  }

  get isBear() {
    return (
      this.offerwallType === offerType.PICCOMA_APP ||
      this.offerwallType === offerType.PICCOMA_WEB ||
      (this.offerwallType === offerType.LINE_MANGA && this.banners.length === 0)
    );
  }

  get isIos() {
    const { platform, userAgent } = navigator;
    return (
      /iPad|iPhone|iPod|Mac/i.test(userAgent) ||
      (platform === "MacIntel" && navigator.maxTouchPoints > 1)
    );
  }

  get isAndoroid() {
    const { userAgent } = navigator;
    return /android|windows/i.test(userAgent);
  }

  get userPoint() {
    return 0;
  }

  get isPiccomaWeb() {
    return this.offerwallType === offerType.PICCOMA_WEB;
  }

  get isPiccomaWebNotLogin() {
    const { userAgent } = navigator;
    const queries = new URLSearchParams(window.location.search);
    const suid = queries.get("suid") ?? "";
    const ua = /iPhone|iPod|android.+Mobile/i.test(userAgent)
      ? "sp"
      : /iPad|android/i.test(userAgent)
      ? "tab"
      : "pc";

    return ua !== "sp" && this.isPiccomaWeb ? true : this.isPiccomaWeb && !suid;
  }

  get piccomaWebUrl() {
    return process.env.REACT_APP_PICCOMA_WEB_URL;
  }

  // ピッコマ汎用化タイプ ピッコマタイプではあるがピッコマではない
  get isPiccomaTemplate() {
    return this.offerwallType === offerType.LINE_MANGA;
  }

  get isMangaType() {
    return this.offerwallType === offerType.MANGA;
  }
}
